import React, { Component } from 'react';
import styles from './NavBar.module.scss'
import { MdFlight,MdCardTravel,MdEmail,MdClose} from "react-icons/md";
import { FaSearch,FaHome,FaCarAlt,FaFacebook,FaPhoneAlt,FaTwitterSquare,FaYoutubeSquare,FaBars} from "react-icons/fa";
import { PiPhoneDisconnectFill } from "react-icons/pi";
//import Router, { withRouter } from 'next/router';
import { LuPhone } from "react-icons/lu";
import { SiFacebook } from "react-icons/si";
import { RiFacebookCircleLine } from "react-icons/ri";
import { FiInstagram } from "react-icons/fi";
import { TiSocialTwitterCircular } from "react-icons/ti";
import { MdOutlineEmail } from "react-icons/md";
import {IoLogoWhatsapp} from 'react-icons/io';
 class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state={
            queryName:'',
            openAccDiv:false,
            openLogInModal:false,
            isLogged:false,
            progress:false,
            showModal:false
        }
      }
    onScroll = () => { 
        const isTop=window.scrollY>100;
        const nav=document.getElementById(styles.shopNav);
        if(isTop){
            nav.classList.add(styles.shopScrolled);
        }else{
            nav.classList.remove(styles.shopScrolled);
        }
    }
     componentDidMount(){
      
        window.addEventListener("scroll", this.onScroll, false);
         
    }
    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll, false);
       // window.removeAllListeners("scroll");
    }
  
    searchChange=(event)=>{
      this.setState({
        queryName:event.target.value
      })
       }
       openTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:true
        })
       }
       closeTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:false
        })
       }
       stopShift=(event)=>{
        //event.stopPropagation();
       // event.preventDefault()
       }
       /*scrollToDiv = (id) => {
        const section = document.querySelector(id);
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      };
      scrollToDiv2 = async (id) => {
        this.setState({openAccDiv:false})
        const section = document.querySelector(id);
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      }*/

      /* scrollToDiv = async (id) => {
        //this.props.goToDiv(id)
        Router.push(id)
      }
      scrollToDiv2 = async (id) => {
        this.setState({openAccDiv:false})
        Router.push(id)
       
      }*/
        scrollToDiv = async (id) => {
          this.props.goToDiv(id)
        }
      openInNewTab = () => {
        const newWindow = window.open('https://www.paypal.com/donate/?hosted_button_id=CCV8WGAXHU4GQ', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    showModal=(result)=>{
      this.setState({showModal:result})
  }
  doNothing=(event)=>{
      event.preventDefault()
      event.stopPropagation()
   }
    render() {
        return (
          <>
            <div  className={styles.shopNav}  id={styles.shopNav}>
              <div className={styles.contactsDiv}>
              {/*<MdOutlineEmail  className={styles.mediaIcons} style={{marginRight:5}}/>*/}
              <p>Contacts: +254 725408540 | macprintlimited@gmail.com  | info@macprint.co.ke</p>
              {/*<PiPhoneDisconnectFill className={styles.mediaIcons} style={{marginRight:5}}/>*/}
              {/*<p>+254 725408540</p>*/}
              {/*<RiFacebookCircleLine   className={styles.mediaIcons}/>
              <FiInstagram   className={styles.mediaIcons}/>
              <TiSocialTwitterCircular size={18}  className={styles.mediaIcons}/>*/}
              </div>
                 <div  className={styles.shopNav0}>
                 <div  className={styles.shopNav2}>
                  <div className={styles.logoCont}>
                    <img style={{cursor:'pointer'}}  src={"logo3.jpeg"} onClick={()=>this.scrollToDiv('/#HOME')}></img>
                    <div> <p className={styles.logoP}>MAC<span>PRINT</span></p>
                    <p id={styles.logoP2}>LIMITED</p></div>
                  </div>
                 
                 <div className={styles.navMain}>
                 <div  className={styles.shopNav3}>
                 <li   className={styles.ulLink} onClick={()=>this.scrollToDiv('HOME')}>Home</li>
                 <li   className={styles.ulLink} onClick={()=>this.scrollToDiv('ABOUT')}>About Us</li>
                 <li   className={styles.ulLink} onClick={()=>this.scrollToDiv('PRODUCTS')}>Products</li>
                 <li   className={styles.ulLink} onClick={()=>this.scrollToDiv('BLOGS')}>Blogs</li>
                 <li   className={styles.ulLink} onClick={()=>this.scrollToDiv('CONTACTS')}>Contact Us</li>
                </div>
                </div>
                <div className={styles.btnsDiv}>
                {/*<PiPhoneDisconnectFill className={styles.phoneIC}/>*/}
                <p className={styles.phoneP} onClick={()=>this.setState({showModal:true})}>Enquire</p>
                </div>
                </div>
                {this.state.openAccDiv?<MdClose  className={styles.optionsIcon}  size={40} onClick={()=>this.closeTheAcDiv()}/>:
                <FaBars  className={styles.optionsIcon} size={30} onClick={()=>this.openTheAcDiv()}/>}
                </div>
                {this.state.openAccDiv?<div  className={styles.optionsDiv}>
                 <li  className={styles.ulLink5} onClick={()=>this.scrollToDiv2('/#HOME')}>Home</li>
                 <li  className={styles.ulLink5} onClick={()=>this.scrollToDiv2('/#ABOUT')}>About Us</li>
                 <li  className={styles.ulLink5} onClick={()=>this.scrollToDiv2('/#PRODUCTS')}>Products</li>
                 <li  className={styles.ulLink5} onClick={()=>this.scrollToDiv2('/#BLOGS')}>Blogs</li>
                 <li  className={styles.ulLink5} onClick={()=>this.scrollToDiv2('/#CONTACTS')}>Contact Us</li>
                {/*} <div className={styles.btnsDiv2}>
                <LuPhone className={styles.phoneIC}/>
                 <p>+254 725408540</p>
                </div>*/}
                {/*<button className={styles.donBtn}>Donate</button>
                 <button className={styles.champBtn}>Become a Champion</button>*/}
                </div>:null}
                {this.state.showModal?<div className={styles.modal} onClick={()=>this.showModal(false)}>
                <div className={styles.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Purchase/Enquire</h5>
                <p className={styles.book}>For purchasing or making enquiries of our products please reach us through the below contacts</p>
                <div className={styles.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#2f628b'/><p>+254 725 408 540</p></div>
                <div className={styles.contDiv}><MdEmail className='ulicon' size={16} color='#2f628b'/><p>macprintlimited@gmail.com</p></div>
                <p className={styles.email2P}>info@macprint.co.ke</p>
                <div className={styles.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#2f628b'/><p>+254 725 408 540</p></div>
                <p id={styles.close} onClick={()=>this.showModal(false)}>Close</p>
                </div>
                </div>:null}
            </div>
            </>
        )
    }
}
export default NavBar