import React, { Component } from 'react'
import style from './Blog.module.scss';
import { RiTeamFill } from 'react-icons/ri';
import firebase from '../FirebaseClient'
//import Router, { withRouter } from 'next/router';

class Section3 extends Component {
    state={
         details:[]
    }
    componentDidMount() {
       
        this.getBlogs()
      }
      getBlogs=async()=>{
        var theNewItems=[],i=0
     var blogRef=firebase.database().ref('/macprint/blogs')
     blogRef.once('value',dataSnapshot=>{
      dataSnapshot.forEach(data=>{
          i++
          theNewItems.push(data.val())
      })
      if(i===dataSnapshot.numChildren()){
          console.log('the value',theNewItems)
          this.setState({details:theNewItems})
      }
         
     })
    }
    render() {
        const detailsList=this.state.details.slice(0,3).map((item,index)=>{
            return(
                <div className={style.causedivzoom} key={index}>
                <img src={item.image}/>
                <h1>{item.title}</h1>
                <p className={style.p1}>Posted: {item.date}</p>
                <p className={style.p1B}>By: {item.writer}</p>
                <p className={style.descP}>{item.desc}</p>
                <p className={style.p2}>See More...</p> 
                </div>
            )
        })
        return (
            <div className={style.blogContainer}>
                {/*<p className={style.ourTit}>OUR BLOGS</p>
                <h1 className={style.meetNews}>{'LATEST NEWS & ARTICLES'}</h1>*/}
                <div className={style.causesItemsCont}>
                 {detailsList}
                </div>
            </div>
        )
    }
}

export default Section3
