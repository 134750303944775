import React, { Component } from 'react'
import style from './Testimonials.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {FaQuoteLeft,FaQuoteRight} from "react-icons/fa";
const theTopics=[
    {id:4,title:'BEATRICE LOLA - HOSPITAL',path:'/marketing', subTitle:"We received our first order and the circle stickers were of great quality and delivered in a timely manner. We liked them so much, that I just ordered a batch for another project. Thanks Macprint Team!", image:'https://images.pexels.com/photos/8312669/pexels-photo-8312669.jpeg?auto=compress&cs=tinysrgb&w=600'},
    {id:2,title:'SHAH PATEL - SUPERMARKET',path:'/about', subTitle:"My stickers are perfect. They did a fantastic job. Great quality, fast turnaround and the prices were fair. Look no further than Macprint. Thank you!", image:'https://images.pexels.com/photos/28442318/pexels-photo-28442318/free-photo-of-confident-businessman-in-formal-suit-portrait.jpeg?auto=compress&cs=tinysrgb&w=600'},
    {id:3,title:'CATHERINE KIM - CHEMIST',path:'/marketing', subTitle:"I was able to get 3000 labels from Macprint at a very reasonable price! It didn't take that long to receive them and when I did, they looked FANTASTIC! I couldn't be happier with them! Will be using them again and highly recommend!", image:'https://images.pexels.com/photos/18351014/pexels-photo-18351014/free-photo-of-businesswoman-presenting-cosmetics-of-her-company.jpeg?auto=compress&cs=tinysrgb&w=600'},
    {id:1,title:'OKELLO OTIENO - MANUFACTURER',path:'/studio', subTitle:"These were amazing Labels! The quality was just what I needed. I gave them to a few friends and they were thrilled. I would absolutely order from this company again. Not only was the price great for the product, but the turnaround was fast.", image:'https://images.pexels.com/photos/13580690/pexels-photo-13580690.jpeg?auto=compress&cs=tinysrgb&w=600'},
    

   
    ]
class Reel extends Component {
  state={
    theAdsArray:[],
    slidesToShow: 2,
    slidesToScroll: 2,
  }
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
      
      }
      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }
    
      updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
        if (window.innerWidth<800) {this.setState({slidesToShow:1,slidesToScroll:1})
        }else{this.setState({slidesToShow:2,slidesToScroll:2})}
      };
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
     
    
    render() {
         let donateStyle=''
         let reelTextStyle=''
        const settings = { 
          infinite: true,
          slidesToShow:this.state.slidesToShow,
          slidesToScroll:this.state.slidesToScroll,
          className:"slides",
          dots: true,
          //fade: true,
          autoplay: true,
          pauseOnHover: false,
    speed: 700,
    autoplaySpeed: 5000,
    cssEase: "linear",
      
          };
        return (
            <div className={style.boduTitleMainCont}>
                {/*<h2 className={style.headP}>CUSTOMERS REVIEWS</h2>*/}
                <Slider ref={c => (this.slider = c)} {...settings}>
                  
                {theTopics.map((item)=>{
                 var theTitle=item.title.split('-')
                 return(
                       <div  className={style.titleDivCont} key={item.id}>
                            
                        <div className={style.testDiv}>
                        <div className={style.bodyLowerCard}>
                     <div className={style.prodImageContainer}>
                     <img className={style.productsImage} src={item.image} alt='Products Image'></img>
                     </div>
                     <div className={style.prodDetContainer}>
                     <h2 className={style.desc}><FaQuoteLeft className={style.quotA}/>{item.subTitle}<FaQuoteRight className={style.quotB}/></h2>
                     <h3 className={style.title}>{theTitle[0]}<span> - {theTitle[1]}</span></h3>
                     </div>
                    </div>  
                        </div>
                       </div>
                )})}

                </Slider>
                
            </div>
        )
    }
}
export default Reel
