import React, { Component } from 'react'
import style from './Reel.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Router, { withRouter } from 'next/router';
const theReel = [
  { id: 1, title: 'SELF ADHESIVE LABELS MANUFACTURERS',title2:'Welcome to Macprint Limited', color: 'black', details: "Where we help you create more value.", det2: 'Learn More', image: './photo11.jpg' },
  { id: 2, title: 'WE DELIVER TOP NOTCH QUALITY',title2:'Deal with proffessionals', color: 'black', details: 'with advisors that know your market', det2: 'Learn More', image: './photo12.jpg'},
  { id: 3, title: 'ENJOY DISCOUNTS ON BULK ORDERS',title2:'Fair Pricing', color: 'black', details: 'Ensuring your company gets the advisory board it deserves', det2: 'Learn More', image: 'photo2.jpg' },
]

class Reel extends Component {
  state = {
    theAdsArray: [], email: ''
  }
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)

  }
  inputChange(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ email: event.target.value });
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  scrollToPos = () => {
    window.scrollTo(0, 550)
  }
  notify = (message) => {
    toast.warn(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  };
  sendMessage = async () => {
    console.log('name', this.state.email)
    if (this.state.email === '' || this.state.email.length < 4) {
      this.notify('Please enter a valid email')
    } else {
      this.notify('Message send successfully')
    }
  }
  goToSignUp=()=>{
   alert('going to sign up')
  }
 /* scrollToDiv = async (id) => {
    Router.push(id)
  }*/
    scrollToDiv = async (id) => {
      this.props.goToDiv(id)
    }
  render() {
    let donateStyle = ''
    let reelTextStyle = ''
    let titleStyle = ''
    const settings = {

      fade: true,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "linear"

    };
    return (
      <div className={style.boduTitleMainCont}>
        <Slider ref={c => (this.slider = c)} {...settings}>

          {theReel.map((item) => {
            if (item.color != 'black') {
              donateStyle = {
                backgroundColor: '#174905'
              }
              reelTextStyle = {
                color: '#fff'
              }
              titleStyle = {
                color: '#fff'
              }
            } else {
              donateStyle = {
                backgroundColor: '#174905'
              }
              reelTextStyle = {
                color: '#fff'
              }
              titleStyle = {
                color: '#fff'
              }
            }
            return (
              <div className={style.titleDivCont} key={item.id}>
                <div className={style.titleCont}>
                  <div className={style.titleCont2}>
                    <p id={style.title2P}>{item.title2}</p>
                    <h4 className={style.titleH4} id={style.titleH4} style={titleStyle}>{item.title}</h4>
                    {/*<p className={style.detailsP} style={reelTextStyle}>{item.details} </p>*/}
                    <button id={style.seeBtn} onClick={()=>this.scrollToDiv('PRODUCTS')}>SHOP</button>

                  </div>
                  {/*item.title?<h4 className={style.titleH4} id={style.titleH4} style={titleStyle}>{item.title}</h4>:null}
                       {item.details?<p className={style.detailsP} style={reelTextStyle}>{item.details} </p>:null*/}
                </div>
                {/*<div className={style.aroowsDiv}>
                      <FaAngleLeft className={style.backFang}  onClick={()=>this.previous()}/>
                      <FaAngleRight className={style.frontFang}  onClick={()=>this.next()}/>
                      </div>*/}
                <img className={style.theImage} src={item.image} />


              </div>

            )
          })}

        </Slider>
        <ToastContainer />
      </div>
    )
  }
}
export default Reel
