import React, { Component } from 'react'
import style from './About.module.scss';

export class About extends Component {
  render() {
    return (
        <div className={style.cont} ref={this.aboutRef} id="ABOUT">
        <div className={style.cont2}>
              <img className={style.theImage} src='https://images.pexels.com/photos/2565761/pexels-photo-2565761.jpeg?auto=compress&cs=tinysrgb&w=600'/>
              <img style={{marginLeft:10}} className={style.theImage} src='https://images.pexels.com/photos/15763942/pexels-photo-15763942/free-photo-of-bottle-of-carbonated-water.jpeg?auto=compress&cs=tinysrgb&w=600'/>
              </div>
              <div className={style.cont1}>
                  <p id={style.p1}>Welcome to Macprint</p>
                  <p className={style.p2}>{("We partner with you to drive business growth").toUpperCase()}</p>
                  <p className={style.p3}>Macprint is a printing company that specializes in manufacturing self adhesive labels for a variety of markets and products.</p> 
<p className={style.p4}>What we do</p> 
<p className={style.p4B}>Our services include:</p>   
<ul>
<li>Water Labels</li>
<li>Cosmetics Labels</li>
<li>Veterinary Labels</li>
<li>Pharmaceuticals Labels</li>
<li>Agro Exporters Labels</li>
<li>Hospitals Labels</li>
<li>Detergents Labels</li>
<li>Supermarkets Labels</li>
<li>Manufacturing Company Labels</li>
<li>Plain labels</li>
<li>UCR Labels</li>
<li>Barcodes Labels</li>
</ul>      
  </div>      
          </div>
    )
  }
}

export default About