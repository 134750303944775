import React, { createRef,Component } from 'react'
import styles from './Footer.module.scss';
import { FaMapMarkerAlt,FaPhone } from 'react-icons/fa';
import { IoMdMail } from "react-icons/io";
import { RiFacebookCircleLine } from "react-icons/ri";
import { MdOutlineEmail,MdOutlineLocationOn    } from "react-icons/md";
import { PiPhoneFill } from "react-icons/pi";
import { TiSocialTwitterCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import { LuPhone } from "react-icons/lu";
//import Router, { withRouter } from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import axios from "axios"
class Footer extends Component {
  form = createRef();
    state={
        name:'',
        email:'',
        message:'',
        value:'Others'
    }
    inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({[event.target.id]:event.target.value});
      }
      notify=(message)=>{
        toast.warn(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      sendMessage=async(e)=>{
        e.preventDefault()
        if (this.state.name===''||this.state.email===''||this.state.message==='') {
            this.notify('All fields must be filled') 
           return
         }else  if (this.state.name!=''&&this.state.email!=''&&this.state.message!='') {
          var service_id=process.env.REACT_APP_EMAILJS_SERVICEID
          var template_id=process.env.REACT_APP_EMAILJS_TEMPLATEID
          var user_id=process.env.REACT_APP_EMAILJS_PUBLICKEY

          console.log('service_id',service_id)
          console.log('template_id',template_id)
          console.log('user_id',user_id)
           
           var templateParams={
            from_name:this.state.name,
            from_email:this.state.email,
            to_name:'Macprint',
            message:this.state.message,
          }
           emailjs.sendForm(service_id, template_id, e.target,user_id).then(
             (response)=>{
              console.log('SUCCESS!', response.status, response.text);
              this.notify('Message send successfully')
              this.setState({ name:'',email:'',message:''})
            },
             (err)=>{
              console.log('FAILED...', err);
              this.notify('An error occured while sending message')
            },
          );
        }
      }
      sendMessage2=async(e)=>{
        e.preventDefault()
        if (this.state.name===''||this.state.email===''||this.state.message==='') {
            this.notify('All fields must be filled') 
           return
         }else  if (this.state.name!=''&&this.state.email!=''&&this.state.message!='') {
           const data={
            service_id:process.env.REACT_APP_EMAILJS_SERVICEID,
            template_id:process.env.REACT_APP_EMAILJS_TEMPLATEID,
            user_id:process.env.REACT_APP_EMAILJS_PUBLICKEY,
            templateParams:{
            from_name:this.state.name,
            from_email:this.state.email,
            to_name:'Macprint',
            message:this.state.message,
          }}
          try {
           await axios.post("https://api.emailjs.com/api/v1.0/email/send",data)
           this.setState({ name:'',email:'',message:''})
           this.notify('Message send successfully')
          } catch (error) {
            this.notify('An error occured while sending message')
          }
        }
      }
      scrollToDiv = async (id) => {
        this.props.goToDiv(id)
      }
     /* scrollToDiv = async (id) => {
        Router.push(id)
        //this.props.goToDiv(id)
      }*/
    render() {
        return (
            <div className={styles.container} id="CONTACTS">
            <div  className={styles.footerDiv}>
                <div  className={styles.footerDivs}>
                 <h1  className={styles.divCentreHead1} style={{marginBottom:5, fontSize:22, fontWeight:'500', color:"#fff"}}>MACPRINT</h1>
                 {/*<p  className={styles.divCentreHead} style={{marginTop:5, fontSize:16, lineHeight:1.5,color:"#fff"}}>We are a printing company dedicated in manufacturing Self Adhehesive Labels for businesses and individuals.</p>*/}
                
                 <div className={styles.locDiv} style={{marginTop:10}}>
                  <MdOutlineLocationOn    className={styles.locIC}/>
                  <p>Njiru Industrial Park</p></div>
                 <p className={styles.locDivP2}>Kangundo Road</p>
                 <div className={styles.locDiv}>
                  <LuPhone   className={styles.locIC}/>
                  <p>+254 725408540</p></div>
                  <div className={styles.locDiv}>
                  <MdOutlineEmail   className={styles.locIC}/>
                  {<p>macprintlimited@gmail.com</p>}
                  
                  {/*<p>info@macprint.co.ke</p>*/}
                  </div>
                  <p className={styles.locDivP2}>info@macprint.co.ke</p>
                 <div  className={styles.aboutUsDiv} style={{display:'flex', flexDirection:'row', marginTop:20}}>
                      
                   
                     {/*<RiFacebookCircleLine  size={25} color="#fff"/>
                     <FaInstagram  size={25} color="#fff" style={{marginLeft:10}}/>
                     <TiSocialTwitterCircular  size={30} color="#fff" style={{marginLeft:10,marginTop:-3}}/>
                     <TiSocialLinkedinCircular  size={30} color="#fff" style={{marginLeft:10,marginTop:-3}}/>*/}
                 </div>
                </div>
                <div  className={styles.footerDivs}>
                <h1  className={styles.divCentreHead1} style={{margin:0, fontSize:22, fontWeight:'500', color:"#fff"}}>Quick Links</h1>
                    <ul  className={styles.ulFooter}>
                        <li onClick={()=>this.scrollToDiv('HOME')} id={styles.FooterLi}>Home</li>
                        <li onClick={()=>this.scrollToDiv('ABOUT')} id={styles.FooterLi}>About Us</li>
                        <li onClick={()=>this.scrollToDiv('PRODUCTS')} id={styles.FooterLi}>Products</li>
                        <li onClick={()=>this.scrollToDiv('BLOGS')} id={styles.FooterLi}>Blogs</li>
                    </ul>
                      
                 </div>
                <div  className={styles.footerDivs}>
                <h1  className={styles.contactUs}>Stay In Touch</h1>
                <form ref={this.form} onSubmit={(e)=>this.sendMessage(e)}>
                <input  className={styles.contactsInput} placeholder='First Name' type='text' name='from_name' id='name' value={this.state.name}  onChange={(event)=>this.inputChange(event)}></input>
                <input  className={styles.contactsInput} placeholder='Email Address' type='text' name='from_email' id='email' value={this.state.email}  onChange={(event)=>this.inputChange(event)}></input>
                <input  className={styles.contactsInput} placeholder='Message' type='text' id='message' name='message' value={this.state.message}  onChange={(event)=>this.inputChange(event)}></input>
                <button type='submit' className={styles.submitBtn}>Send</button>
                </form>
                {/*<div  className={styles.footerSendDiv} onClick={()=>this.sendMessage()}>
                       <p   style={{fontSize:16, color:'#fff', margin:0}}>Send</p>
                       </div>*/}
                
                 </div>
                
            </div>
            <div  className={styles.lineDiv}>
              <p className={styles.copyRightP}>© Copyright 2025 MACPRINT Limited. All rights reserved.</p>
            </div>
            <ToastContainer/>
            </div>
        )
    }
}

export default Footer



