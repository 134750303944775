import firebase from 'firebase' 
const firebaseConfig ={
  
  apiKey: "AIzaSyBaq-PKKfOFX8X1fwiZALIVddjyge1CKt8",
  authDomain: "ea360app.firebaseapp.com",
  projectId: "ea360app",
  storageBucket: "ea360app.appspot.com",
  messagingSenderId: "212189404266",
  appId: "1:212189404266:web:bd88efc25bc8c0b7d13e13"
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  export default firebase;