import React, { Component } from 'react'
import style from './shop.module.scss';
import {MdLocationOn,MdHistory,MdDashboard,MdStar,MdEmail} from "react-icons/md";
import {FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';
export class about extends Component {
    state={
        showModal:false,
        details:[
            
            {id:1,name:'Water Labels',price:' KES 1,600.00',cat:'Labels', image:'./waterLabels.png'},
            {id:2,name:'Cosmetic Labels',price:'KES 2,500.00',cat:'Labels', image:'./cosmeticsLabels.png'},
            {id:3,name:'Vet Labels',price:'Free',cat:'Labels', image:'./vetLabels.png'},
            {id:4,name:'Pharmaceutical Labels',price:' KES 1,600.00',cat:'Labels', image:'./pharmaceuticalLabels.png'},
            {id:5,name:'Plain Labels',price:'KES 2,500.00',cat:'Labels', image:'./plainLabels.png'},
            {id:6,name:'Agro Exporters Labels',price:'Free',cat:'Labels', image:'./agroExportersLabels.png'},
            {id:7,name:'UCR Labels',price:'KES 2,500.00',cat:'Labels', image:'./ucrLabels.png'},
            {id:8,name:'Barcode Labels',price:'Free',cat:'Labels', image:'./barcodeLabels.png'}
                ]
   }
   componentDidMount() {
       window.scrollTo(0, 0)
     }
     showModal=(result)=>{
        this.setState({showModal:result})
    }
    doNothing=(event)=>{
        event.preventDefault()
        event.stopPropagation()
     }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
                <div className={style.causedivzoom} key={item.id}>
                <img src={item.image}/>
            <div className={style.detDiv}>
            <p className={style.prodTitle}>{item.name}</p>
            <div>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
            </div>
           
            <div className={style.detDiv2}>
                <MdDashboard size='14' color='#858585'/>
                <p>{item.cat}</p>
            </div>
            <p className={style.orderP} onClick={()=>this.setState({showModal:true})}>Order Now</p>
            </div>
            
                </div>
            )
        })
        return (
           <>
            <div className={style.causesItemsCont}>
                 {detailsList}
                </div> 
            {this.state.showModal?<div className={style.modal} onClick={()=>this.showModal(false)}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Purchase/Enquire</h5>
                <p className={style.book}>For purchasing or making enquiries of this product please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#2f628b'/><p>+254 725 408 540</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#2f628b'/><p>macprintlimited@gmail.com</p></div>
                <p className={style.email2P}>info@macprint.co.ke</p>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#2f628b'/><p>+254 725 408 540</p></div>
                <p id={style.close} onClick={()=>this.showModal(false)}>Close</p>
                </div>
                </div>:null}</>
               
        )
    }
}

export default about
