import React, { Component } from 'react'
import styles from './App.module.scss'
import NavBar from './components/NavFooter/NavBar'
import Footer from './components/NavFooter/Footer'
import Reel from './components/Home/Reel'
import Testimonials from './components/Home/Testimonials'
import Blog from './components/Home/Blog'
import Products from './components/Home/shop'
import About from './components/Home/About'

export class App extends Component {
  constructor(props) {
    super(props)
   // this.homeRef = React.createRef()  
    this.aboutRef = React.createRef()  
    this.productsRef=React.createRef()
    this.blogsRef = React.createRef() 
    this.contactsRef = React.createRef() 
}
 componentDidMount(){
  window.scrollTo(0, 0)
 }
 goToDiv=(divId)=>{
  if(divId==='ABOUT'){this.aboutRef.current.scrollIntoView()}
  if(divId==='PRODUCTS'){this.productsRef.current.scrollIntoView()}
  if(divId==='BLOGS'){this.blogsRef.current.scrollIntoView()}
  if(divId==='CONTACTS'){this.contactsRef.current.scrollIntoView()}
  if(divId==='HOME'){window.scrollTo(0, 0)}
  
}
 render() {
  return (
    <>
    <NavBar goToDiv={this.goToDiv}/>
    <div className={styles.container}>
        <Reel goToDiv={this.goToDiv}/>
        <div ref={this.aboutRef}><About /></div>
          <div className={styles.theCont1}  ref={this.productsRef}>
                <p className={styles.servP1}>Our Products</p>
                <p className={styles.servP2}><span className={styles.servP2A}>Our Products</span><span className={styles.servP2B}> & Services</span></p>
                <Products/>
            </div>
           <div className={styles.theCont1} style={{backgroundColor:'#fff'}} >
                <p className={styles.servP1}>Your Feedback</p>
                <p className={styles.servP2}><span className={styles.servP2A}>What People</span><span className={styles.servP2B}> Say About Us</span></p>
                <Testimonials/>
            </div>
            <div className={styles.theCont2}  ref={this.blogsRef}>
                <p className={styles.servP1}>Blogs</p>
                <p className={styles.servP2}><span className={styles.servP2A}>Latest News</span><span className={styles.servP2B}> & Articles</span></p>
                <Blog/>
            </div>
      </div>
      <div ref={this.contactsRef}><Footer  goToDiv={this.goToDiv}/></div>
      </>
  );
}
}
export default App;
